/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Form, withFormik, Field, ErrorMessage as ErrMsg } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import * as Yup from 'yup'
import Button from './button'
import { recaptcha_key } from '../data/config'

const formCSS = `
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
`

const formGroup50CSS = `
  width: 48%;
  @media (max-width: 750px) {
    width: 98%;
  }
`

const formGroupCss = css`
  position: relative;
  margin: 0 1% 1.5rem;
  width: 100%;
  > label {
    font-size: 0.9rem;
    font-weight: 500;
  }
  > h4 {
    margin-bottom: 1rem;
  }
  > h5 {
    margin-bottom: 0.5rem;
  }

  ${props => (props.half ? formGroup50CSS : 'width: 100%;')}
`

const FormGroup = styled.div`
  ${formGroupCss}
`

const RadioGroup = styled.fieldset`
  ${formGroupCss}
  display: flex;
  flex-direction: column;
  label {
    font-size: 0.85rem;
    font-weight: normal;
    margin-bottom: 0.25rem;
    width: fit-content;
  }
  input {
    margin-right: 5px;
  }
`

const CheckBoxGroup = styled.fieldset`
  ${formGroupCss}
  > label {
    font-size: 0.85rem;
    font-weight: normal;
    display: inline-block;
    margin-bottom: 0.75rem;
    white-space: nowrap;
    width: 25%;
    @media (max-width: 920px) {
      width: 33.3%;
    }
    @media (max-width: 750px) {
      width: 50%;
    }
    @media (max-width: 370px) {
      width: 100%;
    }
  }
`

const CheckField = styled(Field)`
  margin-right: 0.5rem;
  transform: scale(1.15);
`

const ErrorMessage = styled(ErrMsg)`
  color: #ff4136;
  display: block;
  font-size: 0.8rem;
  margin-left: 0.25rem;
`

const WebsiteQuestionnaireForm = ({
  setFieldValue,
  isSubmitting,
  values,
  errors,
  handleChange,
  touched,
}) => {
  useEffect(() => {
    Array.from(document.querySelectorAll('[type="radio"],[type="checkbox"]')).forEach(element => {
      console.log(element)
      if (!element.id) {
        console.log(element)
      }

      element.parentNode.setAttribute('for', element.id)
    })
  }, [])
  return (
    <Form
      name="Website Questionnaire"
      noValidate
      method="post"
      data-netlify="true"
      data-netlify-recaptcha="true"
      data-netlify-honeypot="bot-field"
      css={formCSS}
    >
      <h4
        css={`
          margin-left: 1%;
          width: 100%;
        `}
      >
        Tell us about you and your company
      </h4>
      <FormGroup half>
        <label htmlFor="full-name" className="required">
          Full Name
        </label>
        <Field
          id="full-name"
          type="text"
          name="name"
          aria-label="name"
          required
          error={touched.name && errors.name}
        />
        <ErrorMessage component={'span'} name="name" />
      </FormGroup>
      <FormGroup half>
        <label htmlFor="email" className="required">
          Email
        </label>
        <Field
          id="email"
          type="email"
          name="email"
          required
          error={touched.email && errors.email}
        />
        <ErrorMessage component={'span'} name="email" />
      </FormGroup>
      <FormGroup half>
        <label htmlFor="company" className="required">
          Company
        </label>
        <Field id="company" component="input" name="company" required />
        <ErrorMessage component={'span'} name="company" />
      </FormGroup>
      <FormGroup half>
        <label htmlFor="website">Website URL</label>
        <Field id="website" component="input" name="website" />
        <ErrorMessage component={'span'} name="website" />
      </FormGroup>

      {/* Basic development info */}
      <h4
        css={`
          margin-left: 1%;
          width: 100%;
        `}
      >
        Tell us about your expectations for the project
      </h4>
      <RadioGroup>
        <legend className="required">
          Do you want to improve an existing website, or create a website from
          scratch?
        </legend>
        <Field name="updateExistingOrFromScratch" required>
          {({ field }) => (
            <>
              <label>
                <input
                  {...field}
                  id="updateExistingOrFromScratch-yes"
                  value="Improve existing website"
                  checked={field.value === 'Improve existing website'}
                  type="radio"
                />
                Improve existing website
              </label>
              <label>
                <input
                  {...field}
                  id="updateExistingOrFromScratch-no"
                  value="Start From Scratch"
                  checked={field.value === 'Start From Scratch'}
                  type="radio"
                />
                Start From Scratch
              </label>
            </>
          )}
        </Field>
        <ErrorMessage component={'span'} name="updateExistingOrFromScratch" />
      </RadioGroup>

      <RadioGroup half>
        <legend className="required">
          How many pages will the website have?
        </legend>
        <Field name="numberOfPages" required>
          {({ field }) => (
            <>
              <label>
                <input
                  {...field}
                  id="1-10"
                  value="1 - 10"
                  checked={field.value === '1 - 10'}
                  type="radio"
                />
                1 to 10 Pages
              </label>

              <label>
                <input
                  {...field}
                  id="11-25"
                  value="11 - 25"
                  checked={field.value === '11 - 25'}
                  type="radio"
                />
                11 to 25 Pages
              </label>

              <label>
                <input
                  {...field}
                  id="26-75"
                  value="26 - 75"
                  checked={field.value === '26 - 75'}
                  type="radio"
                />
                26 to 75 Pages
              </label>

              <label>
                <input
                  {...field}
                  id="76-150"
                  value="76 - 150"
                  checked={field.value === '76 - 150'}
                  type="radio"
                />
                76 to 150 Pages
              </label>

              <label>
                <input
                  {...field}
                  id="151-250"
                  value="151 - 250"
                  checked={field.value === '151 - 250'}
                  type="radio"
                />
                151 to 250 Pages
              </label>

              <label>
                <input
                  {...field}
                  id="250+"
                  value="250+"
                  checked={field.value === '250+'}
                  type="radio"
                />
                Over 250 Pages
              </label>
            </>
          )}
        </Field>
        <ErrorMessage component={'span'} name="numberOfPages" />
      </RadioGroup>
      <RadioGroup half>
        <legend className="required">
          What is your budget for the website?
        </legend>
        <Field name="budget" required>
          {({ field }) => (
            <>
              <label>
                <input
                  {...field}
                  id="<3000"
                  value="Under $3,000"
                  checked={field.value === 'Under $3,000'}
                  type="radio"
                />
                Under $3,000
              </label>

              <label>
                <input
                  {...field}
                  id="3000-5000"
                  value="$3,000 - $5,000"
                  checked={field.value === '$3,000 - $5,000'}
                  type="radio"
                />
                $3,000 to $5,000
              </label>

              <label>
                <input
                  {...field}
                  id="5000-10000"
                  value="$5,000 - $10,000"
                  checked={field.value === '$5,000 - $10,000'}
                  type="radio"
                />
                $5,000 to $10,000
              </label>

              <label>
                <input
                  {...field}
                  id="10000-25000"
                  value="$10,000 - $25,000"
                  checked={field.value === '$10,000 - $25,000'}
                  type="radio"
                />
                $10,000 to $25,000
              </label>

              <label>
                <input
                  {...field}
                  id="25000-50000"
                  value="$25,000 - $50,000"
                  checked={field.value === '$25,000 - $50,000'}
                  type="radio"
                />
                $25,000 to $50,000
              </label>

              <label>
                <input
                  {...field}
                  id="50000+"
                  value="$50,000+"
                  checked={field.value === '$50,000+'}
                  type="radio"
                />
                Over $50,000
              </label>
            </>
          )}
        </Field>
        <ErrorMessage component={'span'} name="budget" />
      </RadioGroup>

      <RadioGroup half>
        <legend className="required">
          Will you need active maintenance for you website?
        </legend>
        <Field name="activeMaintenance" required>
          {({ field }) => (
            <>
              <label>
                <input
                  {...field}
                  id="aactiveMaintenance-yes"
                  value="Yes, I want you to maintain the website"
                  checked={
                    field.value === 'Yes, I want you to maintain the website'
                  }
                  type="radio"
                />
                Yes, I want you to maintain the website
              </label>

              <label>
                <input
                  {...field}
                  id="activeMaintenance-no"
                  value="No, we will maintain the website"
                  checked={field.value === 'No, we will maintain the website'}
                  type="radio"
                />
                No, we will maintain the website
              </label>
            </>
          )}
        </Field>
        <ErrorMessage component={'span'} name="activeMaintenance" />
      </RadioGroup>

      <RadioGroup half>
        <legend className="required">
          Do you want us to manage hosting for the website?
        </legend>
        <Field name="hosting" required>
          {({ field }) => (
            <>
              <label>
                <input
                  {...field}
                  id="hosting-yes"
                  value="Yes, we want you to manage hosting"
                  checked={field.value === 'Yes, we want you to manage hosting'}
                  type="radio"
                />
                Yes, we want you to manage hosting
              </label>

              <label>
                <input
                  {...field}
                  id="hosting-no"
                  value="No, we will set up hosting"
                  checked={field.value === 'No, we will set up hosting'}
                  type="radio"
                />
                No, we will set up hosting
              </label>
            </>
          )}
        </Field>
        <ErrorMessage component={'span'} name="hosting" />
      </RadioGroup>

      <FormGroup
        css={`
          margin-bottom: 1.5rem;
          h5 {
            margin-bottom: 0.5rem;
          }
        `}
      >
        <label htmlFor="targetLaunchDate" className="required">
          What is your target launch date for the new website?
        </label>
        <br />
        <Field
          id="targetLaunchDate"
          name="targetLaunchDate"
          type="date"
          component="input"
          required
          css={`
            max-width: 12.5rem;
          `}
        />
        <ErrorMessage component={'span'} name="targetLaunchDate" />
      </FormGroup>

      {/* What sites do you like? */}
      <FormGroup
        css={`
          margin: 0;
          > div {
            display: inline-block;
          }
          input {
            margin-bottom: 0.5rem;
          }
        `}
      >
        <legend
          css={`
            margin-left: 1%;
          `}
        >
          Are there any websites you like for their design? List 0-4 below:
        </legend>
        <FormGroup half>
          <Field
            id="referenceSiteOneUrl"
            aria-label="reference website 1 url"
            component="input"
            name="referenceSiteOneUrl"
            placeholder="Website URL"
          />
          <Field
            id="referenceSiteOneLikes"
            aria-label="what do you like about reference website 1"
            component="input"
            name="referenceSiteOneLikes"
            placeholder="What do you like about this website?"
          />
        </FormGroup>
        <FormGroup half>
          <Field
            id="referenceSiteTwoUrl"
            aria-label="reference website 2 url"
            component="input"
            name="referenceSiteTwoUrl"
            placeholder="Website URL"
          />
          <Field
            id="referenceSiteTwoLikes"
            aria-label="what do you like about reference website 2"
            component="input"
            name="referenceSiteTwoLikes"
            placeholder="What do you like about this website?"
          />
        </FormGroup>
        <FormGroup half>
          <Field
            id="referenceSiteThreeUrl"
            aria-label="reference website 3 url"
            component="input"
            name="referenceSiteThreeUrl"
            placeholder="Website URL"
          />
          <Field
            id="referenceSiteThreeLikes"
            aria-label="what do you like about reference website 3"
            component="input"
            name="referenceSiteThreeLikes"
            placeholder="What do you like about this website?"
          />
        </FormGroup>
        <FormGroup half>
          <Field
            id="referenceSiteFourUrl"
            aria-label="reference website 4 url"
            component="input"
            name="referenceSiteFourUrl"
            placeholder="Website URL"
          />
          <Field
            id="referenceSiteFourLikes"
            aria-label="what do you like about reference website 4"
            component="input"
            name="referenceSiteFourLikes"
            placeholder="What do you like about this website?"
          />
        </FormGroup>
      </FormGroup>
      {/* END What sites do you like? */}

      {/* Website Features */}
      <CheckBoxGroup>
        <legend>What features do you need on your website?</legend>
        <label>
          <CheckField id="ads" name="ads" component="input" type="checkbox" />
          Advertisements
        </label>
        <label>
          <CheckField
            id="adminArea"
            name="adminArea"
            component="input"
            type="checkbox"
          />
          Admin Area
        </label>
        <label>
          <CheckField id="blog" name="blog" component="input" type="checkbox" />
          Blog
        </label>
        <label>
          <CheckField
            id="chatBot"
            name="chatBot"
            component="input"
            type="checkbox"
          />
          Chat Bot
        </label>
        <label>
          <CheckField
            id="contactForm"
            name="contactForm"
            component="input"
            type="checkbox"
          />
          Contact Form
        </label>
        <label>
          <CheckField
            id="contentManagementSystem"
            name="contentManagementSystem"
            component="input"
            type="checkbox"
          />
          CMS
        </label>
        <label>
          <CheckField
            id="donations"
            name="donations"
            component="input"
            type="checkbox"
          />
          Donations
        </label>
        <label>
          <CheckField
            id="eCommerce"
            name="eCommerce"
            component="input"
            type="checkbox"
          />
          E-Commerce
        </label>
        <label>
          <CheckField
            id="eventCalendar"
            name="eventCalendar"
            component="input"
            type="checkbox"
          />
          Event Calendar
        </label>
        <label>
          <CheckField
            id="interactiveMap"
            name="interactiveMap"
            component="input"
            type="checkbox"
          />
          Interactive Map
        </label>
        <label>
          <CheckField
            id="interactiveFoodMenu"
            name="interactiveFoodMenu"
            component="input"
            type="checkbox"
          />
          Interactive Menu
        </label>
        <label>
          <CheckField
            id="appointmentBooking"
            name="appointmentBooking"
            component="input"
            type="checkbox"
          />
          Appointment Booking
        </label>
        <label>
          <CheckField
            id="newsletterSignup"
            name="newsletterSignup"
            component="input"
            type="checkbox"
          />
          Newsletter Signup
        </label>
        <label>
          <CheckField
            id="memberArea"
            name="memberArea"
            component="input"
            type="checkbox"
          />
          Member Area
        </label>
        <label>
          <CheckField
            id="onlineChat"
            name="onlineChat"
            component="input"
            type="checkbox"
          />
          Online Chat
        </label>
        <label>
          <CheckField
            id="onlineMealOrdering"
            name="onlineMealOrdering"
            component="input"
            type="checkbox"
          />
          Online Meal Ordering
        </label>
        <label>
          <CheckField
            id="projectPortfolio"
            name="projectPortfolio"
            component="input"
            type="checkbox"
          />
          Project Portfolio
        </label>
        <label>
          <CheckField
            id="websiteSearch"
            name="websiteSearch"
            component="input"
            type="checkbox"
          />
          Website Search
        </label>
        <label>
          <CheckField
            id="webStatistics"
            name="webStatistics"
            component="input"
            type="checkbox"
          />
          Website Statistics
        </label>
        <Field
          type="text"
          name="otherFeatures"
          component="input"
          aria-label="other features"
          placeholder="Other Features"
        />
      </CheckBoxGroup>

      <FormGroup>
        <label htmlFor="worriesOrConcerns">
          Do you have any worries or concerns about building a new website?
        </label>
        <Field
          as="textarea"
          id="worriesOrConcerns"
          rows="5"
          type="text"
          name="worriesOrConcerns"
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="message">Any additional comments or questions?</label>
        <Field as="textarea" id="message" rows="5" type="text" name="message" />
      </FormGroup>
      {!values.success && (
        <div
          css={`
            position: relative;
            margin-left: 1%;
            margin-bottom: 1rem;
          `}
        >
          <ReCAPTCHA
            sitekey={recaptcha_key}
            name="recaptcha"
            onChange={value => setFieldValue('recaptcha', value)}
          />
          <ErrorMessage component={'span'} name="recaptcha" />
        </div>
      )}
      {values.success && (
        <div
          css={`
            position: relative;
            margin-bottom: 1rem;
            max-width: 500px;
          `}
        >
          <div>
            <h4>
              Your questionnaire has been successfully sent, typically you will
              get a response within 1-2 days. Thank you.
            </h4>
          </div>
        </div>
      )}
      {!values.success && (
        <div
          css={`
            margin-right: 1%;
          `}
        >
          <Button type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </div>
      )}
    </Form>
  )
}

export default withFormik({
  validateOnBlur: false,
  mapPropsToValues: () => {
    let urlParams
    if (typeof window !== 'undefined') {
      urlParams = new URLSearchParams(window.location.search)
      window.history.pushState(
        'website-questionnaire',
        'Website Questionnaire',
        '/website-questionnaire'
      )
    } else {
      urlParams = new Map()
    }
    return {
      name: urlParams.get('name') || '',
      email: urlParams.get('email') || '',
      company: urlParams.get('company') || '',
      website: urlParams.get('website') || '',

      // Project expectation
      updateExistingOrFromScratch:
        urlParams.get('updateExistingOrFromScratch') || '',
      numberOfPages: urlParams.get('numberOfPages') || '',
      budget: urlParams.get('budget') || '',
      activeMaintenance: urlParams.get('activeMaintenance') || '',
      hosting: urlParams.get('hosting') || '',
      targetLaunchDate: urlParams.get('targetLaunchDate') || '',

      // Inspo
      referenceSiteOneUrl: urlParams.get('referenceSiteOneUrl') || '',
      referenceSiteOneLikes: urlParams.get('referenceSiteOneLikes') || '',
      referenceSiteTwoUrl: urlParams.get('referenceSiteTwoUrl') || '',
      referenceSiteTwoLikes: urlParams.get('referenceSiteTwoLikes') || '',
      referenceSiteThreeUrl: urlParams.get('referenceSiteThreeUrl') || '',
      referenceSiteThreeLikes: urlParams.get('referenceSiteThreeLikes') || '',
      referenceSiteFourUrl: urlParams.get('referenceSiteFourUrl') || '',
      referenceSiteFourLikes: urlParams.get('referenceSiteFourLikes') || '',

      // Features
      ads: !!urlParams.get('ads'),
      adminArea: !!urlParams.get('adminArea'),
      blog: !!urlParams.get('blog'),
      chatBot: !!urlParams.get('chatBot'),
      contactForm: !!urlParams.get('contactForm'),
      contentManagementSystem: !!urlParams.get('contentManagementSystem'),
      donations: !!urlParams.get('donations'),
      eCommerce: !!urlParams.get('eCommerce'),
      eventCalendar: !!urlParams.get('eventCalendar'),
      fileSharing: !!urlParams.get('fileSharing'),
      interactiveMap: !!urlParams.get('interactiveMap'),
      interactiveFoodMenu: !!urlParams.get('interactiveFoodMenu'),
      appointmentBooking: !!urlParams.get('appointmentBooking'),
      newsletterSignup: !!urlParams.get('newsletterSignup'),
      memberArea: !!urlParams.get('memberArea'),
      onlineChat: !!urlParams.get('onlineChat'),
      onlineMealOrdering: !!urlParams.get('onlineMealOrdering'),
      projectPortfolio: !!urlParams.get('projectPortfolio'),
      websiteSearch: !!urlParams.get('websiteSearch'),
      webStatistics: !!urlParams.get('webStatistics'),
      otherFeatures: urlParams.get('otherFeatures') || '',

      worriesOrConcerns: '',
      message: '',
      recaptcha: '',
      success: false,
    }
  },
  validationSchema: () =>
    Yup.object().shape({
      name: Yup.string().required('Full name is required'),
      email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
      company: Yup.string().required('Company is required'),
      website: Yup.string(),

      updateExistingOrFromScratch: Yup.string().required('Answer Required'),
      numberOfPages: Yup.string().required('Number of Pages Required'),
      budget: Yup.string().required('Budget Required'),
      activeMaintenance: Yup.string().required('Answer Required'),
      hosting: Yup.string().required('Answer Required'),
      targetLaunchDate: Yup.string().required('Target Launch Date Required'),

      referenceSiteOneUrl: Yup.string(),
      referenceSiteOneLikes: Yup.string(),
      referenceSiteTwoUrl: Yup.string(),
      referenceSiteTwoLikes: Yup.string(),
      referenceSiteThreeUrl: Yup.string(),
      referenceSiteThreeLikes: Yup.string(),
      referenceSiteFourUrl: Yup.string(),
      referenceSiteFourLikes: Yup.string(),

      ads: Yup.boolean(),
      adminArea: Yup.boolean(),
      blog: Yup.boolean(),
      chatBot: Yup.boolean(),
      contactForm: Yup.boolean(),
      contentManagementSystem: Yup.boolean(),
      donations: Yup.boolean(),
      eCommerce: Yup.boolean(),
      eventCalendar: Yup.boolean(),
      interactiveMap: Yup.boolean(),
      interactiveFoodMenu: Yup.boolean(),
      appointmentBooking: Yup.boolean(),
      newsletterSignup: Yup.boolean(),
      memberArea: Yup.boolean(),
      onlineChat: Yup.boolean(),
      onlineMealOrdering: Yup.boolean(),
      projectPortfolio: Yup.boolean(),
      websiteSearch: Yup.boolean(),
      webStatistics: Yup.boolean(),
      otherFeatures: Yup.string(),

      worriesOrConcerns: Yup.string(),
      message: Yup.string(),
      recaptcha: Yup.string()
        .nullable()
        .required('Robots are not welcome yet!'),
    }),
  handleSubmit: async (
    {
      name,
      email,
      company,
      website,
      updateExistingOrFromScratch,
      numberOfPages,
      budget,
      targetLaunchDate,
      activeMaintenance,
      hosting,
      referenceSiteOneUrl,
      referenceSiteOneLikes,
      referenceSiteTwoUrl,
      referenceSiteTwoLikes,
      referenceSiteThreeUrl,
      referenceSiteThreeLikes,
      referenceSiteFourUrl,
      referenceSiteFourLikes,
      ads,
      adminArea,
      blog,
      chatBot,
      contactForm,
      contentManagementSystem,
      donations,
      eCommerce,
      eventCalendar,
      fileSharing,
      interactiveMap,
      interactiveFoodMenu,
      appointmentBooking,
      newsletterSignup,
      memberArea,
      onlineChat,
      onlineMealOrdering,
      projectPortfolio,
      websiteSearch,
      webStatistics,
      otherFeatures,
      worriesOrConcerns,
      message,
      recaptcha,
    },
    { setSubmitting, setFieldValue }
  ) => {
    try {
      const encode = data => {
        return Object.keys(data)
          .map(key => {
            let value
            switch (typeof data[key]) {
              case 'boolean':
                value = data[key] ? 'Yes' : 'No'
                break
              case 'string':
                value = data[key] ? data[key] : 'No Response'
                break
              default:
                value = data[key]
            }

            console.log(`${key}: ${value} (${typeof data[key]})`)
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          })
          .join('&')
      }

      await fetch('/website-questionnaire?no-cache=1', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'Website Questionnaire',
          name,
          email,
          company,
          website,
          updateExistingOrFromScratch,
          numberOfPages,
          budget,
          targetLaunchDate,
          activeMaintenance,
          hosting,
          referenceSiteOneUrl,
          referenceSiteOneLikes,
          referenceSiteTwoUrl,
          referenceSiteTwoLikes,
          referenceSiteThreeUrl,
          referenceSiteThreeLikes,
          referenceSiteFourUrl,
          referenceSiteFourLikes,
          ads,
          adminArea,
          blog,
          chatBot,
          contactForm,
          contentManagementSystem,
          donations,
          eCommerce,
          eventCalendar,
          fileSharing,
          interactiveMap,
          interactiveFoodMenu,
          appointmentBooking,
          newsletterSignup,
          memberArea,
          onlineChat,
          onlineMealOrdering,
          projectPortfolio,
          websiteSearch,
          webStatistics,
          otherFeatures,

          worriesOrConcerns,
          message,
          'g-recaptcha-response': recaptcha,
        }),
      })
      await setSubmitting(false)
      await setFieldValue('success', true)
    } catch (err) {
      setSubmitting(false)
      setFieldValue('success', false)
      alert('Something went wrong, please try again!') // eslint-disable-line
    }
  },
})(WebsiteQuestionnaireForm)
