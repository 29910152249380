import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import WebsiteQuestionnaireForm from '../components/websiteQuestionnaire'
import '../styles/website-questionnaire.css'

const WebsiteQuestionnaire = () => (
  <Layout>
    <SEO
      title="Website Questionnaire"
      meta={[
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ]}
    />
    <section
      css={`
        height: 100%;
        margin-top: 4rem;
        width: 100%;
        z-index: 1;
      `}
    >
      <h2
        css={`
          margin-left: 1%;
        `}
      >
        Website Development Questionnaire
      </h2>
      <WebsiteQuestionnaireForm />
    </section>
  </Layout>
)

export default WebsiteQuestionnaire
